import { repliesDto, replyDto } from "../types/dto";
import { PBP_USER_PREFIX, REPLY_PREFIX, apiGet, apiPostJson } from "./utils";

export interface createReplyData {
    pbpVideoId: number;
    accountUserId: number;
    parentReplyId?: string | null | undefined;
    rootReplyId: string | null | undefined;
    note: string;
}

export const createReply = async (data: createReplyData) => {
    const response = await apiPostJson(`${REPLY_PREFIX}/create`, data);
    const res: replyDto = await response.json();
    return res;
};

export const getPostReplies = async (pbpVideoId: number, accountUserId: number, rootReplyId: string | null ) => {
    const parentIdClause = rootReplyId ? `&rootReplyId=${rootReplyId}` : ``;
    const response = await apiGet(`${REPLY_PREFIX}/post?pbpVideoId=${pbpVideoId}&userId=${accountUserId}${parentIdClause}`);
    const res: repliesDto = await response.json();
    return res;
};

export const likeReply = async (
    likedAccountUserId: number,
    replyId: string,
) => {
    const data = { likedAccountUserId, replyId };
    const response = await apiPostJson(`${REPLY_PREFIX}/likereply/`, data);
    return true;
};

export const unlikeReply = async (
    likedAccountUserId: number,
    replyId: string,
) => {
    const data = { likedAccountUserId, replyId };
    const response = await apiPostJson(`${REPLY_PREFIX}/unlikereply/`, data);
    return true;
};
