import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { calculateMaxVideoSize } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import {
  createUserProfilePath
} from "../utils/url-utils";
import FollowButton from "./FollowButton";
import UserPostHeader from "./UserPostHeader";
interface clipPlaylistViewWrapperProps {
  viewItemUrl: string | null;
  username: string;
  ownerUserId: number;
  userFollows: boolean;
  userCanFollow: boolean;
  itemDate: Date;
  children: ReactJSXElement
}
export default function ClipPlaylistViewWrapper(props: clipPlaylistViewWrapperProps) {
  const userAuth = useAuth();
  const userId = userAuth?.userId ?? null;

  const navigate = useNavigate();
  const border = "1px solid grey";

  const onClick = () => {
    console.log('onclick', props.viewItemUrl, window.getSelection())
    if (props.viewItemUrl) {
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        return; // Do nothing, prevent the navigation
      }
      navigate(props.viewItemUrl);  
    }
  }
  return (
    <Card
      onClick={onClick}
      sx={{
        maxWidth: calculateMaxVideoSize(),
        paddingBottom: "10px",
        paddingX: {
          xs:1,
          sm: 2
        },
        margin: "auto",
        background: "none",
        borderBottom: border,
        borderRadius: "0px",
        '&:hover': props.viewItemUrl ? {
          cursor: 'pointer',
          backgroundColor: (theme) => theme.palette.action.hover,
        } : {},
      }}
    >
      <CardHeader
        sx={{ pl: '10px', pr: '10px', pt: "10px", pb: "10px" }}
        // avatar={
        //   <Link
        //     to={userProfilePath}
        //     style={{ color: "white", textDecoration: "none" }}
        //   >
        //     {/* <Avatar>{note.username}</Avatar> */}
        //   </Link>
        // }
        title={
          <Grid container sx={{ justifyContent: "space-between" }}>
            <UserPostHeader username={props.username} userId={props.ownerUserId} itemDate={props.itemDate} />
            <FollowButton
              onClick={null}
              loggedInUserId={userId}
              followedUserId={props.ownerUserId}
              userFollows={props.userFollows}
              userCanFollow={props.userCanFollow}
            />
          </Grid>
        }
      />
      {props.children}
    </Card>
  );
}
