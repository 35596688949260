import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WinnerIcon from "@mui/icons-material/EmojiEvents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import VideoIcon from "@mui/icons-material/Videocam";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/Settings';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../api/firebase";
import { setGlobalNavigate } from "../api/utils";
import { BOTTOM_NAV_SIZE_PX } from "../common/css-constants";
import defaultTheme from "../common/theme";
import { useAuth } from "../context/auth-context";
import {
  CLIP_TIMELINE_PATH,
  createUserProfilePath,
  FILM_PATH,
  LEADERS_PATH,
  USER_PLAYLISTS_PATH,
  USER_PROFILE_PATH,
  USER_SETTINGS_PATH
} from "../utils/url-utils";
import Typography from "@mui/material/Typography";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DesktopDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface dashItemProps {
  navFunc: undefined | (() => void);
  text: string;
  icon: React.ReactElement;
  path: string;
}


interface NavProps {
  children?: React.ReactNode;
}

export default function Nav(props: NavProps) {
  const [bottomNavVal, setBottomNavVal] = React.useState(-1);
  const navigate = useNavigate();
  setGlobalNavigate(navigate);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const authContext = useAuth();
  const mode = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
  const isScreenDesktop = useMediaQuery("(min-width:600px)");
  const noScreenPadding = useMediaQuery("(min-width:1000px)")
  useEffect(() => {
    if (location.pathname === CLIP_TIMELINE_PATH) setBottomNavVal(0);
    else if (location.pathname === FILM_PATH) setBottomNavVal(1);
    else if (location.pathname === USER_PLAYLISTS_PATH)setBottomNavVal(2);
    else if (location.pathname === USER_PROFILE_PATH) setBottomNavVal(3);
    else if (location.pathname === LEADERS_PATH) setBottomNavVal(authContext.userId !== null ? 4 : 3);
    else setBottomNavVal(-1);
  }, [location])
  const createNavFunc = (location: string) => {
    return () => {
      navigate(location);
      setOpen(false);
    };
  };

  const DashIcon = (props: dashItemProps) => (
    <ListItemButton
    // sx={{'&.Mui-selected': {
    //   backgroundColor: '#FEFF5F',
    //   backgroundColor: 'rgba(254, 255, 95, 0.44)',
    // }}}
    selected={location.pathname === props.path}
      disabled={props.navFunc === undefined}
      onClick={props.navFunc}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItemButton>
  );
  const drawerContent = (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      <Divider />

      <List component="nav">
        <DashIcon
          path={CLIP_TIMELINE_PATH}
          navFunc={createNavFunc(CLIP_TIMELINE_PATH)}
          text="Posts"
          icon={<SportsBasketballIcon />}
        />
        <DashIcon
          path={FILM_PATH}
          navFunc={createNavFunc(FILM_PATH)}
          text="Watch Film"
          icon={<VideoIcon />}
        />
        <DashIcon
          path={USER_PLAYLISTS_PATH}
          navFunc={authContext.userId !== null
            ? createNavFunc(
              USER_PLAYLISTS_PATH
            )
            : undefined}
          text="Manage Playlists"
          icon={<PlaylistPlayIcon />}
        />
        <DashIcon
          path={USER_PROFILE_PATH}
          navFunc={
            authContext.userId !== null
              ? createNavFunc(
                createUserProfilePath(authContext.userId as number),
              )
              : undefined
          }
          text="Profile"
          icon={<PersonIcon />}
        />
        <DashIcon
          path={USER_SETTINGS_PATH}
          navFunc={
            authContext.userId !== null
              ? createNavFunc(USER_SETTINGS_PATH)
              : undefined
          }
          text="Settings"
          icon={<SettingsIcon />}
        />
        {/* <DashIcon
                path={}
        navFunc={undefined} text="Followers" icon={<PeopleIcon />} /> */}
        <DashIcon
          path={LEADERS_PATH}
          navFunc={createNavFunc(LEADERS_PATH)}
          text="Leaders"
          icon={<WinnerIcon />}
        />
        <Divider sx={{ my: 1 }} />
        {
          open && <Typography paddingLeft='16px'>Feedback:
            <br />crowdscoutops@gmail.com</Typography>
        }
      </List>
    </>
  );

  const BottomNavActionStyled = styled(BottomNavigationAction)({
    '&.Mui-selected': {
      color: '#ffea00',
    },
  });
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          // position="sticky"
          // position="absolute"
          open={open}
        >
          <Toolbar
            sx={{
              backgroundColor: "#282828",
              color: (theme) => theme.palette.secondary.light,
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              onClick={toggleDrawer}
              sx={{ flexGrow: 1 }}
            > */}
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="img"
                onClick={toggleDrawer}
                sx={{
                  height: 25,
                  verticalAlign: "middle",
                  // width: 350,
                  // maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 170, md: 350 },
                }}
                src="/ICON_TRANSPARENT.png"
              />
              {/* Crowdscout */}
              <IconButton
                edge="start"
                onClick={toggleDrawer}
                // color="inherit"
                aria-label="open drawer"
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>

            {/* </Typography> */}
            {authContext.userId ? (
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, mb: 1, whiteSpace: "nowrap" }}
                onClick={async () => {
                  const res = await logoutUser();
                  authContext.refreshAuth();
                }}
              >
                Log out
              </Button>
            ) : (
              <Link to="/login">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 1, mb: 1, whiteSpace: "nowrap" }}
                >
                  Log in
                </Button>
              </Link>
            )}
          </Toolbar>
        </AppBar>
        <DesktopDrawer
          sx={{ display: { xs: "none", md: "block" } }}
          variant="permanent"
          open={open}
        >
          {drawerContent}
        </DesktopDrawer>
        <Drawer
          sx={{
            display: { xs: "block", md: "none" },
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="temporary"
          open={open}
        >
          {drawerContent}
        </Drawer>
        <Box
          component="main"
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === 'light'
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            mt: "64px",
            paddingBottom: isScreenDesktop ? "0px" : "56px",
            flexGrow: 1,
            height: `calc(100vh - ${BOTTOM_NAV_SIZE_PX}px)`,
            overflow: "auto",
          }}
        >
          <Container maxWidth={false} sx={{ mt: 0, mb: 0, paddingX: noScreenPadding ? '0px !important' : ''}}>{props.children}</Container>
          {/* <Footer /> */}
        </Box>
        <BottomNavigation
          sx={{
            borderTop: "1px solid #424242",
            position: "fixed",
            zIndex: '100',
            bottom: 0,
            left: 0,
            right: 0,
            display: { md: "none" },
          }}
          showLabels
          value={bottomNavVal}
        // onChange={(event, newValue) => {
        //   setBottomNavVal(newValue);
        // }}
        >
          <BottomNavActionStyled
            label="Posts"
            icon={<SportsBasketballIcon />}
            onClick={createNavFunc(CLIP_TIMELINE_PATH)}
          />
          <BottomNavActionStyled
            label="Film"
            icon={<VideoIcon />}
            onClick={createNavFunc(FILM_PATH)}
          />
          <BottomNavActionStyled
            label="Playlists"
            icon={<PlaylistPlayIcon />}
            disabled={authContext.userId === null}
            onClick={() => {
              if (authContext.userId !== null)
                createNavFunc(USER_PLAYLISTS_PATH)();
            }}
          />
          {authContext.userId !== null && (
            <BottomNavActionStyled
              label="Profile"
              icon={<PersonIcon />}
              disabled={authContext.userId === null}
              onClick={() => {
                if (authContext.userId !== null)
                  createNavFunc(createUserProfilePath(authContext.userId as number))();
              }}
            />
          )}
          <BottomNavActionStyled
            label="Leaders"
            icon={<WinnerIcon />}
            onClick={createNavFunc(LEADERS_PATH)}
          />
        </BottomNavigation>
      </Box>
    </ThemeProvider>
  );
}
