import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../api/auth";
import { getAccountAuth, getUserId, getUserToken, onAuthStateChangeCb } from "../api/firebase";
import { userDetailsDto } from "../types/dto";
import { defaultAuth } from "../types/user-authorization";
import { LOGIN_PATH } from "../utils/url-utils";
import { usePlaylistStore } from "./playlist-store";
const defaultContext = {
  userId: null as number | null,
  accountLevel: defaultAuth,
  featureFlag: false,
  refreshAuth: async () => null as number | null,
  userDetails: null as userDetailsDto | null,
  refreshUserDetails: async () => true,
};
const AuthContext = createContext(defaultContext);

interface authProviderProps {
  children: React.ReactNode;
}

export const useAuth = () => {
  return useContext(AuthContext);
}
export const AuthProvider = (props: authProviderProps) => {
  const [userId, _setUserId] = useState(defaultContext.userId);
  const [accountLevel, _setAccountLevel] = useState(defaultContext.accountLevel);
  const [featureFlag, _setFeatureFlag] = useState(defaultContext.featureFlag);
  const [userDetails, setUserDetails] = useState(defaultContext.userDetails);
  const navigate = useNavigate();
  const refreshUserPlaylists = usePlaylistStore((state) => state.refreshUserPlaylists);

  const refreshAuth = async () => {
    await getUserToken(true);
    const userId = await getUserId();
    const accountAuth = await getAccountAuth();
    _setUserId(userId);
    _setAccountLevel(accountAuth);
    return userId;
  };

  const refreshUserDetails = async () => {
    const details = await getUserDetails();
    setUserDetails(details);
    return true;
  }

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    _setFeatureFlag(urlParams.get("admin") !== null);
    const unsubscribe = onAuthStateChangeCb(async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        refreshAuth().then(x => refreshUserPlaylists(x));
      } else {
        // User is signed out
        _setUserId(null);
        _setAccountLevel(defaultAuth);
        navigate(LOGIN_PATH)
        refreshUserPlaylists(null);
      }
      return true;
    })

    return unsubscribe;
  }, []);


  useEffect(() => {
    refreshUserPlaylists(userId);
    if (userId) {
      refreshUserDetails();  
    } else {
      setUserDetails(defaultContext.userDetails);
    }
  }, [userId]);

  const value = {
    userId,
    accountLevel,
    featureFlag,
    userDetails,
    refreshAuth,
    refreshUserDetails
  }
  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}
