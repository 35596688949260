import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../context/nba-data";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserGameViews, getUserPlayerViews } from "../api/user";
import { Season } from "../types/constants";
import { playerDto, userGameViewsDto, userPlayerViewsDto } from "../types/dto";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Virtuoso } from "react-virtuoso";
import { textOverflowEllipsisSx } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import { splitUserInput } from "../utils/string-utils";
import PlayerWatchMenu, { playerWatchMenuProps } from "./PlayerWatchMenu";
import Button from "@mui/material/Button";
import { createIframeUrl } from "../utils/url-utils";
import { create } from "@mui/material/styles/createTransitions";
interface playerListProps {
  season: Season;
  userSearch: string;
  userSearchForDisplay: string;
}

type Order = "asc" | "desc";

const teamLogoNameSize = '50px';
const headCells = [
  {
    id: "player",
    label: "Player",
  },
  {
    id: "percent",
    label: "%",
  },
];

function getPlayerComparator(
  // order: Order,
  // orderBy: string,
  // playerViews: userPlayerViewsDto,
) {
  return (playerA: playerDto, playerB: playerDto) => {
    let returnVal = 0;

    if (playerB.name < playerA.name) {
      returnVal = -1;
    } else if (playerB.name > playerA.name) {
      returnVal = 1;
    }
    return -returnVal;
    //return order === "desc" ? returnVal : -returnVal;
  };
}



let playerTeams: { [key: string]: Set<number> } = {};
const setPlayerTeams = (allPlayersByTeamId: { [key: string]: playerDto[] }) => {
  playerTeams = {};
  Object.keys(allPlayersByTeamId).forEach((teamId) => {
    allPlayersByTeamId[teamId].forEach((player) => {
      if (playerTeams[player.playerId] === undefined)
        playerTeams[player.playerId] = new Set<number>();
      playerTeams[player.playerId].add(parseInt(teamId));
    });
  });
};

const addTotalHandleNull = (
  key: string,
  val: number,
  dict: { [key: string]: number },
) => {
  const currVal = dict[key];
  dict[key] = dict[key] === undefined ? val : currVal + val;
};

export default function PlayerList(props: playerListProps) {
  const [season, setSeason] = useState(props.season);
  const [playerViews, setPlayerViews] = useState({
    totalPlayerViews: {},
    userPlayerViews: {},
  } as userPlayerViewsDto);
  const [gameViews, setGameViews] = useState({
    totalGameViews: {},
    userGameViews: {},
    userNotes: {},
    userSaved: {},
  } as userGameViewsDto);

  const [selectedPlayer, setSelectedPlayer] = useState(null as null | playerWatchMenuProps);
  const closeDrawer = () => setSelectedPlayer(null);
  const data = useDataContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const virtuosoRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const element = virtuosoRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distanceToViewportTop = rect.top + window.scrollY;
      setDistance(distanceToViewportTop);
    }
  }, []);

  const [userSearch, setUserSearch] = useState(props.userSearch);
  const [userSearchForDisplay, setUserSearchForDisplay] = useState(
    props.userSearchForDisplay,
  );
  useEffect(() => setUserSearch(props.userSearch), [props.userSearch]);
  useEffect(
    () => setUserSearchForDisplay(props.userSearchForDisplay),
    [props.userSearchForDisplay],
  );
  const userAuth = useAuth();
  const allPlayersByTeamId = data.seasonTeamPlayers[season];
  useMemo(() => {
    setPlayerTeams(allPlayersByTeamId);
  }, []);
  useEffect(() => {
    if (userAuth.userId) {
      getUserPlayerViews(userAuth.userId, props.season).then((res) => {
        setPlayerTeams(data.seasonTeamPlayers[props.season]);
        setSeason(props.season);
        setPlayerViews(res);
      });
    }
  }, [props.season]);
  useEffect(() => {
    if (userAuth.userId) {

      getUserGameViews(userAuth.userId, props.season).then((res) => {
        setGameViews(res);
      });
    }
  }, [props.season]);
  const allGames = data.gamesBySeason[season];
  const teamsById = data.teams;

  const userSearchLower = userSearch.toLowerCase();

  const visiblePlayersByTeam = useMemo(() => {
    const playersByTeamId = {} as { [key: string]: playerDto[] };
    const players: playerDto[] = [];
    Object.keys(allPlayersByTeamId).forEach(x => {
      const team = teamsById[x];
      const queryParts = splitUserInput(userSearch);
      const queryPartsWithoutTeamMatch = queryParts.filter(x => {
        return !(userSearch.length === 0 ||
          team.teamCity.toLowerCase().includes(userSearchLower) ||
          team.teamName.toLowerCase().includes(userSearchLower) ||
          team.triCode.toLowerCase().includes(userSearchLower))
      })
      const visiblePlayers = allPlayersByTeamId[x]
        .filter(player => {
          return queryPartsWithoutTeamMatch.length === 0 ||
          queryPartsWithoutTeamMatch.every(part => {
            return player.name.toLowerCase().includes(part);
          })
        });
      if (visiblePlayers.length > 0) playersByTeamId[x] = visiblePlayers;
      visiblePlayers.sort(getPlayerComparator());
      //visiblePlayers.sort(getPlayerComparator(order, orderBy, playerViews));
    })
    return playersByTeamId;
  }, [playerViews, userSearch]);


  const totalsByTeamId = useMemo(() => {
    const user: { [key: string]: number } = {};
    const team: { [key: string]: number } = {};
    allGames.forEach((game) => {
      const userTotal = gameViews.userGameViews[game.gameId] ?? 0;
      const gameTotal = gameViews.totalGameViews[game.gameId] ?? 0;
      addTotalHandleNull(game.homeTeamId, userTotal, user);
      addTotalHandleNull(game.awayTeamId, userTotal, user);
      addTotalHandleNull(game.homeTeamId, gameTotal, team);
      addTotalHandleNull(game.awayTeamId, gameTotal, team);
    });
    return { user, team };
  }, [gameViews]);
  const sortedVisibleTeamIds = useMemo(() => {
    const teamIds = Object.keys(visiblePlayersByTeam);
    teamIds.sort((teamIdA, teamIdB) => {
      const teamA = teamsById[teamIdA];
      const teamB = teamsById[teamIdB];
      let returnVal = 0;
      if (teamB.teamCity < teamA.teamCity) {
        returnVal = -1;
      } else if (teamB.teamCity > teamA.teamCity) {
        returnVal = 1;
      }
      return -returnVal;
      //return order === "desc" ? returnVal : -returnVal;
    });
    return teamIds;
  }, [visiblePlayersByTeam]);
  return (
    <>
      <Grid container sx={{ mt: "10px", maxWidth: "1000px", margin: "auto" }}>
        {
          selectedPlayer !== null &&
          <PlayerWatchMenu
            {...selectedPlayer}
          // season={season} player={player} teamId={teamId} teamPlayerIds={teamPlayerIds}
          />
        }

        <Paper ref={virtuosoRef} sx={{ width: "100%", overflow: "hidden", backgroundImage: 'none' }}>
          <Virtuoso
            style={{
              height: `calc(100vh - ${distance}px)`,
              margin: "auto",
            }}
            data={sortedVisibleTeamIds}
            itemContent={(index, teamId) => {
              const players = visiblePlayersByTeam[teamId];
              const team = teamsById[teamId];
              const teamPlayerIds = new Set(allPlayersByTeamId[teamId].map(x => x.playerId))
              const completeDec = totalsByTeamId.user[teamId] / totalsByTeamId.team[teamId];
              const completePercent = Math.round(completeDec * 100);
              return (
                <>
                  <Grid container marginY='5px' key={teamId}>
                    <img
                      src={`/team-logos/${teamId}.svg`}
                      height={teamLogoNameSize}
                    />
                    <Typography variant='body1' lineHeight={teamLogoNameSize} sx={textOverflowEllipsisSx}>
                      {team.teamCity} {team.teamName}
                    </Typography>
                    <Typography variant='body1' color="text.secondary" lineHeight={teamLogoNameSize} sx={{ marginLeft: '8px', ...textOverflowEllipsisSx }}>
                      {totalsByTeamId.team[team.teamId] > 0 ? completePercent : 0}%
                    </Typography>
                  </Grid>
                  <Grid container gap={1} direction="row" justifyContent="left" sx={{ overflowX: 'scroll', flexWrap: 'nowrap' }}>
                    {
                      players.map(player => {
                        const userTotal =
                          playerViews.userPlayerViews[player.playerId] ?? 0;
                        const playerTotal =
                          playerViews.totalPlayerViews[player.playerId] ?? 0;
                        return (
                          <>
                            <Card sx={{ position: 'relative', maxWidth: '120px', minWidth: '120px', backgroundColor: '#191919', display: 'inline-block', textAlign: 'center', paddingBottom: '10px' }}>
                            {/* <CardMedia
                                title={player.name}
                                sx={{ margin: 'auto', height: 120, width: 120 }}
                                image={`https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.playerId}.png`}
                              /> */}
                              <CardMedia
                                title={player.name}
                              />
                              {/* <iframe
                                height='80px' width='120px'
                                style={{ minHeight: '1px', border: 'none', display: 'block', flexGrow: 1, overflow: 'hidden' }} src={createIframeUrl(`https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.playerId}.png`, false)}>
                              </iframe> */}
                              <CardContent sx={{ padding: 0 }} >
                                {/* <Typography variant="body2" color="text.secondary" sx={{ position: 'absolute', top: 0, right: 0 }}>
                                  {playerTotal > 0
                                    ? Math.round((userTotal / playerTotal) * 100)
                                    : 0}
                                  %
                                </Typography> */}
                                <Typography variant="body2" marginTop='8px' sx={textOverflowEllipsisSx} title={player.name}>
                                  {player.name}
                                </Typography>
                                <Typography variant="body2" marginBottom='5px' color="text.secondary">
                                  {playerTotal > 0
                                    ? Math.round((userTotal / playerTotal) * 100)
                                    : 0}
                                  % viewed
                                </Typography>
                              </CardContent>
                              <CardActions sx={{ paddingX: '5px', paddingY: 0 }} >
                                <Button
                                  fullWidth
                                  size='small'
                                  variant="contained"
                                  onClick={() => setSelectedPlayer({
                                    season, player, teamId, teamPlayerIds, closeDrawer
                                  })}
                                  color="secondary"
                                  sx={{ padding: 0,  margin: 'auto' }}
                                  TouchRippleProps={{ style: { width: "100%" } }}
                                >
                                  Watch
                                </Button>
                              </CardActions>
                            </Card>
                          </>
                        );
                      })
                    }
                  </Grid>
                </>
              )

            }}
          />
        </Paper>
      </Grid>
    </>
  );
}
