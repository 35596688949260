import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { getPostReplies } from "../api/reply";
import { noteDto, replyDto } from "../types/dto";
import Reply from "./Reply";
import ReplyModal from "./ReplyModal";

interface repliesProps {
    pbpVideoId: number;
    userId: number;
    note: noteDto;
}

const TOP_REPLY = "TOP";
export default function Replies(props: repliesProps) {
    const [loaded, setLoaded] = useState(false);
    // const [replies, setReplies] = useState([] as replyDto[])
    const [repliesByParentId, setRepliesByParentId] = useState({} as { [key: string]: replyDto[] })
    const [fetchedAll, setFetchedAll] = useState(true);
    const [fetchedRootIds, setFetchedRootIds] = useState(new Set<string>());
    const {note } = props;

    useEffect(() => {
        getPostReplies(props.pbpVideoId, props.userId, null).then(
            (res) => {
                setLoaded(true);
                // setReplies(res.replies);
                const r = res.replies.reduce((acc, currentValue) => {
                    let groupKey = currentValue.parentReplyId ?? TOP_REPLY;
                    if (!acc[groupKey]) {
                        acc[groupKey] = [];
                    }
                    acc[groupKey].push(currentValue);
                    return acc;
                }, {} as { [key: string]: replyDto[] });
                setFetchedAll(res.fetchedAll);
                setRepliesByParentId(r);
            },
        );
    }, [props.note]);

    const fetchReplyBranch = (rootReplyId: string) => {
        getPostReplies(props.pbpVideoId, props.userId, rootReplyId).then(
            (res) => {
                const r = res.replies.reduce((acc, currentValue) => {
                    let groupKey = currentValue.parentReplyId ?? TOP_REPLY;
                    if (!acc[groupKey]) {
                        acc[groupKey] = [];
                    }
                    acc[groupKey].push(currentValue);
                    return acc;
                }, repliesByParentId);
                fetchedRootIds.add(rootReplyId);
                setFetchedRootIds(new Set(fetchedRootIds));
                setRepliesByParentId(r);
            },
        );
    };

    const onUserReply = (reply: replyDto) => {
        const parentReplyId = reply?.parentReplyId ?? TOP_REPLY;
        if (repliesByParentId[parentReplyId] === undefined) repliesByParentId[parentReplyId]= [];
        repliesByParentId[parentReplyId].push(reply);
        setRepliesByParentId({...repliesByParentId});
    };

    const generateReplyTree = (parentReplyId: string, depth: number): JSX.Element[] => {
        return (repliesByParentId[parentReplyId] ?? []).map((reply, i) => {
            return <Box key={`${parentReplyId}${i}`} sx={{marginLeft: `10px`, 
            // paddingLeft: depth === 0 ? '' : '5px',
            paddingTop: '10px', borderLeft: depth === 0 ? '' : '1px solid #707070'}}>
                <Reply reply={reply} pbpVideoId={props.pbpVideoId} onReply={onUserReply} />
                {
                    (fetchedAll || fetchedRootIds.has(reply.rootReplyId ?? reply.replyId) || reply.replyCount === 0) ?
                        generateReplyTree(reply.replyId, depth + 1) :
                        <Button sx={{marginLeft: '8px'}} size='small' color='secondary' onClick={(e) => {
                            e.stopPropagation();
                            fetchReplyBranch(reply.replyId);
                        }}>Load rest of replies</Button>
                }
            </Box>
        });
    }

    let content = null;
    if (!loaded) content = <Typography variant="body2">Loading replies...</Typography>;
    else content = (<>
        <ReplyModal
            content={note.note}
            userId={note.userId}
            username={note.username}
            date={note.noteUpdateUtc}
            pbpVideoId={note.pbpVideoId}
            parentReplyId={null}
            rootReplyId={null}
            onReply={onUserReply}
            button={<Button size='large' fullWidth color='secondary' sx={{
                paddingLeft: '10px',
                paddingY: '8px', textTransform: 'inherit', justifyContent: 'left', color: 'gray'
            }}>
                <Typography variant='body1' fontSize='1.15rem'>
                    Add your reply
                </Typography>
            </Button>}
        />
        {
            generateReplyTree(TOP_REPLY, 0)
        }
    </>);
    return content;
}