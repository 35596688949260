import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useRef, useState } from "react";
import { getNotesData } from "../api/pbp-user";
import { calculateMaxVideoSize } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import {
  pbpVideoDto,
  pbpVideoLineups,
  userPbpVideoItemDto
} from "../types/dto";
import SituationMenu from "./SituationMenu";
import VideoWorkspace from "./VideoWorkspace";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface videoListProps {
  gamesPbp: pbpVideoLineups,
}

enum ClipMode {
  Clips = "Clips",
  Saved = "Saved",
  Posts = "Posts",
}

const clipModes = [ClipMode.Clips, ClipMode.Saved, ClipMode.Posts];
export default function VideoList(props: videoListProps) {
  const [clipMode, setClipMode] = useState(ClipMode.Clips);
  const [gamesPbp, setGamesPbp] = useState({} as pbpVideoLineups);
  const [autoplay, setAutoplay] = useState(true);
  // since react state can accept a function (and calls the function), we have to return a function by doing () => () => true
  const [filterFunc, setFilterFunc] = useState<(pbp: pbpVideoDto) => boolean>(
    () => () => true,
  );
  // const season = searchParams.get("season") as Season;
  // const gameId = Number(searchParams.get('gameId'));

  // TODO figure out what this is for again
  const firstChildRef = useRef<HTMLDivElement>(null);
  // const fetchData = () => {
    // console.log('fetchData', seasonQs, gameIdQsRaw)
    // if (seasonQs !== null && gameIdQs !== null) {
    //     getGameVideo(searchParams.get("league") as League, season, newGameId)
    //         .then(pbpLineup => {
    //             setGamesPbp(pbpLineup);
    //         });
    // }
    // TODO this needs to also reset your workspace state
  // };
  // useEffect(fetchData, [useLocation()]);

  useEffect(() => {
    setGamesPbp(props.gamesPbp);
  }, [props.gamesPbp])
  const userAuth = useAuth();

  const filteredPbp = gamesPbp.pbpVideo?.filter((x) => filterFunc(x)) ?? [];
  const editLaterPbpVideo =
    filteredPbp.filter(
      (x) => gamesPbp.userPbpVideo?.[x.pbpVideoId]?.isEditLater ?? false,
    ) ?? [];
  const notesPbpVideo = filteredPbp
    .filter(x => gamesPbp.userPbpVideo?.[x.pbpVideoId]?.note ?? false)
    // TODO
  const display = true;// season !== null && gameId !== null;

  const addForEditLaterMutate = (pbpVideoId: number, isEditLater: boolean) => {
    const userPbpVideo = gamesPbp.userPbpVideo?.[pbpVideoId] ?? {
      note: null,
      notePrivacy: null,
    };
    userPbpVideo.isEditLater = isEditLater;
    if (gamesPbp.userPbpVideo !== undefined)
      gamesPbp.userPbpVideo[pbpVideoId] = userPbpVideo;
  };
  const removeEditLaterMutate = (pbpVideoId: number) => {
    if (gamesPbp.userPbpVideo !== undefined)
      delete gamesPbp.userPbpVideo[pbpVideoId];
  };
  const removeEditLater = (pbpVideoId: number) => {
    removeEditLaterMutate(pbpVideoId);
    setGamesPbp({...gamesPbp});
  };
  const addNote = (pbpVideoId: number, userPbpVideo: userPbpVideoItemDto)  => {
    if (gamesPbp.userPbpVideo !== undefined)
      gamesPbp.userPbpVideo[pbpVideoId] = userPbpVideo;
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        style={{
          maxWidth: calculateMaxVideoSize(),
          margin: "auto",
          // height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
          // maxHeight: `calc(100vh - ${APPBAR_HEIGHT}px)`,
          // overflowY: 'hidden'
        }}
      >
        <Grid container flex="0 0 auto" ref={firstChildRef}>
          <Grid item xs={12} textAlign="center">
            {
              display && (
                <Box sx={{ mb: 1, borderBottom: 1, borderColor: 'divider', textColor: 'white' }}>
                  <Tabs variant='fullWidth' textColor='inherit' value={clipMode}
                    onChange={(e, val) => val !== null && setClipMode(val)} >
                    {
                      clipModes
                        .filter(
                          (x) => x === ClipMode.Clips || userAuth.userId !== null,
                        )
                        .map(mode => (
                          <Tab key={mode} label={mode} value={mode} />
                        ))
                    }
                  </Tabs>
                </Box>
              )
            }
            {/* {display && (
                <ToggleButtonGroup
                  fullWidth
                  color="secondary"
                  value={clipMode}
                  // fullWidth
                  size="small"
                  exclusive
                  onChange={(e, newVal) =>
                    newVal !== null && setClipMode(newVal)
                  }
                >
                  {clipModes
                    .filter(
                      (x) => x === ClipMode.Clips || userAuth.userId !== null,
                    )
                    .map((mode) => (
                      <ToggleButton key={mode} value={mode}>
                        {mode}
                      </ToggleButton>
                    ))}
                </ToggleButtonGroup>
              )} */}
          </Grid>
          <Grid item xs={12} textAlign="center" mb={1}>
            {gamesPbp.lineups !== undefined && (
              <SituationMenu
                autoplay={autoplay}
                setAutoplay={setAutoplay}
                lineups={gamesPbp.lineups}
                onFilterChange={(newFunc) => setFilterFunc(newFunc)}
              />
            )}
          </Grid>
          {display && (
            <Container sx={{ paddingX: '0px !important', margin: 0, maxWidth: calculateMaxVideoSize() + 'px !important', gridTemplateRows: "lfr" }}>
              {clipMode === ClipMode.Clips && (
                <VideoWorkspace
                  autoplay={autoplay}
                  pbpVideoLineups={{ ...gamesPbp, pbpVideo: filteredPbp }}
                  isEditLater={false}
                  onEditLater={addForEditLaterMutate}
                  onUpdateNote={addNote}
                />
              )}
              {clipMode === ClipMode.Saved && userAuth.userId !== null && (
                <VideoWorkspace
                  autoplay={autoplay}
                  pbpVideoLineups={{ ...gamesPbp, pbpVideo: editLaterPbpVideo }}
                  isEditLater={true}
                  onEditLater={removeEditLater}
                  onUpdateNote={addNote}
                />
              )}
              {clipMode === ClipMode.Posts && (
                <VideoWorkspace
                  autoplay={autoplay}
                  pbpVideoLineups={{ ...gamesPbp, pbpVideo: notesPbpVideo }}
                  isEditLater={true}
                  onEditLater={() => { }}
                  onUpdateNote={removeEditLaterMutate}
                />
              )}
            </Container>
          )}
        </Grid>
      </Grid>
    </>
  );
}
