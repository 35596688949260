import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getNotes } from "../api/pbp-user";
import { MAX_POST_SIZE_PX } from "../common/css-constants";
import ClipView from "../components/ClipView";
import Replies from "../components/Replies";
import { noteAndPbpDto } from "../types/dto";
import { CLIP_PBP, CLIP_USER } from "../utils/url-utils";

interface clipProps {}

export default function Clip(props: clipProps) {
  const [loaded, setLoaded] = useState(false);
  const [note, setNote] = useState(null as null | noteAndPbpDto);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get(CLIP_USER));
  const pbpVideoId = Number(searchParams.get(CLIP_PBP));
  const pbpVideoIds = [pbpVideoId];
  useEffect(() => {
    getNotes({ userId, pbpVideoIds, orderByTime: true, offset: 0 }).then(
      (res) => {
        setLoaded(true);
        if (res.notes.length > 0) setNote(res.notes[0]);
      },
    );
  }, []);
  let content = null;
  

  const replyText = "laksdfjlajsdf";

  if (!loaded) content = <Typography variant="body2">Loading...</Typography>;
  else if (note === null)
    content = <Typography variant="body2">Post doesn't exist</Typography>;
  else content = <>
    <ClipView isSingleItemView={true} note={note} pbpVideo={note.pbpVideo} />
    {
      note && <Container sx={{ margin: 'auto', maxWidth: `${MAX_POST_SIZE_PX + 50}px !important` }}>
        <Replies userId={userId} pbpVideoId={pbpVideoId} note={note} />
      </Container>
    }
  </>;
  return content;
}
