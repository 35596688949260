import { DialogTitle, Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { createAddPlaylists } from "../api/playlist";
import { NotePrivacy } from "../types/constants";
import ModalBase from "./ModalBase";

import AddIcon from '@mui/icons-material/Add';
import { playerUI } from "../api/nba";
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { teamsDto } from "../types/dto";
import PlayersSelector from "./menu/PlayersSelector";
import TeamSelector from "./menu/TeamSelector";
import NoteText from "./NoteText";
import { usePlaylistStore } from "../context/playlist-store";

interface createPlaylistModal {

}
export default function CreatePlaylistModal(props: createPlaylistModal) {
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [open, _setOpen] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState([] as playerUI[]);
    const [selectedTeams, setSelectedTeams] = useState([] as teamsDto[]);

    const {userPlaylists, refreshUserPlaylists} = usePlaylistStore.getState();

    // const handleClose = () => setOpen(false);
    const auth = useAuth();
    
    const clearState = () => {
        setTitle('');
        setNote('');
        setSelectedPlayers([]);
        setSelectedTeams([]);
    }
    
    const setOpen = (newVal: boolean) => {
        if (!newVal) clearState();
        _setOpen(newVal);
    }

    const onSave = async () => {
        if (title.length < 3) alert('Choose a longer title');
        else {
            const teamId = selectedTeams.length > 0 ? selectedTeams[0].teamId : null;
            const playerId = selectedPlayers.length > 0 ? selectedPlayers[0].playerId : null;
            await createAddPlaylists(title, note, NotePrivacy.Private, [], teamId, playerId);
            await refreshUserPlaylists(auth.userId);
            setOpen(false);
        }
        return true;
    }

    const data = useDataContext();

    return (
        // <>

        //   <Dialog
        //     open={open}
        //     onClose={handleClose}
        //     aria-labelledby="alert-dialog-title"
        //     aria-describedby="alert-dialog-description"
        //   >
        //     {/* <DialogTitle id="alert-dialog-title">
        //       {"Use Google's location service?"}
        //     </DialogTitle> */}
        //     <DialogContent>
        //       <DialogContentText id="alert-dialog-description">
        //         {props.children}
        //       </DialogContentText>
        //     </DialogContent>
        //     <DialogActions>
        //       {/* <Button onClick={handleClose}>Disagree</Button> */}
        //       <Button onClick={handleClose} color="secondary" autoFocus>
        //         Close
        //       </Button>
        //     </DialogActions>
        //   </Dialog>
        // </>
        <ModalBase
            open={open}
            setOpen={setOpen}
            content={
                <>
                    <DialogTitle sx={{ paddingTop: '0px' }}>Create playlist</DialogTitle>
                    <DialogContentText>
                        <Grid container rowSpacing={2} textAlign='center'>
                            <Grid item xs={12}>
                                <TextField
                                    // onKeyDown={stopPropagation}
                                    multiline
                                    label={'Title (required)'}
                                    value={title}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setTitle(event.target.value);
                                    }}
                                    fullWidth
                                    required
                                    placeholder='Title (required)'
                                    color='secondary'
                                    size='small'
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NoteText
                                isEmbed={false}
                                    isPlaylist={true}
                                    userLoggedIn={true}
                                    userIsAuthor={true}
                                    //   userIsAuthor={userIsAuthor}
                                    noteVal={note}
                                    onChange={setNote} />
                            </Grid>
                            <Grid item xs={12}>
                                <TeamSelector
                                    selectedTeams={selectedTeams}
                                    maxItems={1}
                                    label="Tag Team"
                                    onChange={setSelectedTeams}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PlayersSelector
                                    showAbbrs={false}
                                    players={data.allPlayers}
                                    selectedPlayers={selectedPlayers}
                                    maxItems={1}
                                    label="Tag Player"
                                    onChange={setSelectedPlayers}
                                />
                                <Typography variant='caption' component='p' paddingLeft='10px' textAlign='left'>
                                    Tags help people discover your playlist
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </>
            }
            button={
                <IconButton color="secondary" title='Create playlist'>
                    <AddIcon />
                </IconButton>
            }
            actions={
                <>
                    <Button color="secondary">
                        Close
                    </Button>
                    <Button onClick={e => {
                        e.stopPropagation();
                        onSave();
                        }} variant='contained' color="secondary">
                        Save
                    </Button>
                </>

            }
        />
    );
}
