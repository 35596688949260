import { create } from 'zustand'
import { playlistMetaDto } from '../types/dto';
import { getUserPlaylists } from '../api/playlist';

const defaultUserPlaylists={ playlists: [] } as playlistMetaDto;
interface PlaylistStore {
    userPlaylists: playlistMetaDto;
    refreshUserPlaylists: (userId: number | null) => void;
  }

export const usePlaylistStore = create<PlaylistStore>((set) => ({
    userPlaylists: defaultUserPlaylists,
    refreshUserPlaylists: async (userId: number | null) => {
        if (userId) {
            const userPlaylists = await getUserPlaylists(0);
            set({ userPlaylists })
        } else {
            set({ userPlaylists: defaultUserPlaylists })
        }
    }
}));